import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import Layout from 'components/layout';
import Head from 'components/head';
import Logo from 'components/logo';
import Title from 'components/title';
import Wave from 'components/wave';

const centerItems = {
  textAlign: 'center',
};

const Thanks = ({ data }) => (
  <Layout>
    <Head pageTitle={data.thanksJson.title} />
    <Wave />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div style={centerItems}>
            <Logo />
            <Title as="h1" size="large">
              {data.thanksJson.title}
            </Title>
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.thanksJson.content.childMarkdownRemark.html,
            }}
          />
        </Col>
      </Row>
    </Container>
  </Layout>
);

Thanks.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Thanks;

export const query = graphql`
  query ThanksQuery {
    thanksJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
